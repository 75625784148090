import OtpInput from 'react-otp-input';

import './login.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [mobile, setMobile] = useState('');

    return (
        <div className="app-login">
            <div className="welcom-container">
                <div className="container px-4 pt-5">
                    <p className="pt-5 text-primary">Membership Application</p>
                    <h2 className="pb-5 text-dark">Tell us your Mobile Number</h2>
                </div>
            </div>
            <div className="container">
                <form>
                    <div class="input-group mt-5">
                        <input type="number" value={mobile} onChange={({ target: { value } }) => {
                            setMobile(value);
                        }} class="form-control" placeholder="Mobile No" aria-label="Mobile No" aria-describedby="mobile" required />
                        <button type='submit' class="input-group-text" id="basic-addon2">Send OTP</button>
                    </div>
                    <div class="form-check mt-2">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label class="form-check-label text-dark" for="flexCheckChecked">
                            allow Zuno to access your contact information from
                            <a className='ms-1 text-secondary' href='/home'>your contacts.</a>
                        </label>
                    </div>
                </form>
                <div className='w-100 mt-5 otp-container' >
                    <label className='text-primary fw-bold mb-3'>ENTER OTP</label>
                    <OtpInput
                        containerStyle={{ width: '100%' }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span className='px-2' />}
                        renderInput={(props) => <input {...props} className='form-control text-center otp-input' />}
                    />
                    <button className='mt-4 btn-md btn btn-secondary' onClick={() => {
                        navigate('/home')
                    }}>Login Now <img width={20} src='/images/icons/arrow.png' /></button>
                </div>

            </div>
        </div>
    )
}

export default Login;