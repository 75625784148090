const MenuList = ({ menuList }) => {
    return (
        <div className="row mt-5">
            <p>Menu</p>
            {
                menuList.map(e => {
                    return (
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between align-item-center">
                                <img src={e.icon} />
                                <p className="w-100 px-3 pt-2">{e.name}</p>
                                <i class="bi bi-arrow-right text-primary align-middle" style={{ fontSize: '24px' }}></i> 
                                </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default MenuList;