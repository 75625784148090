import { Outlet, useNavigate } from "react-router-dom";

import profile from './icons/profile.svg';
import zuno from './icons/zuno.svg';
import scan from './icons/scan.svg';
import BottomMenu from "./components/bottom-menu/BottomMenu";

const Layout = () => {
  const navigate = useNavigate();


  const buttonsList = [
    { onClick: () => {
      navigate('/profile')
    }, src: profile },
    { onClick: () => {
      navigate('/home')
    }, src: zuno },
    { onClick: () => {
      window.location.href = '/scan'
    }, src: scan },
  ]


  return (
    <>
      <Outlet />
      <BottomMenu buttonsList={buttonsList} />
    </>
  )
};

export default Layout;