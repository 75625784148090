import { useNavigate } from 'react-router-dom';
import './profile.scss';
import MenuList from '../components/list/List';

const Profile = () => {
    const navigate = useNavigate();

    const menu = [
        {
            name: 'shopping coupons',
            icon: '/images/icons/shop.svg'
        }
    ]
    return (
        <div className="zuno-profile px-3 py-3">
            <div className="back-nav d-flex justify-content-between" onClick={() => {
                navigate('/home')
            }}>
                <button className="btn btn-link text-decoration-none rounded back-btn">
                    <i class="bi bi-arrow-left text-primary align-middle" style={{ fontSize: '24px' }}></i> 
                    <span className="ps-2 mt-1 text-dark">Profile</span>
                </button>
                <button className="btn btn-outline-primary btn-sm support-btn rounded"> <i class="bi bi-chat-dots-fill"></i> Support</button>
            </div>
            <MenuList menuList={menu} />
        </div>
    )
}

export default Profile;