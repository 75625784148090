import { useEffect, useState } from "react";
import FloatingButtons from "react-floating-buttons";

const BottomMenu = ({ buttonsList }) => {

    const [open, setOpen] = useState(true);
    let openMenu = false;

    useEffect(() => {
        setTimeout(() => {
            if (!openMenu) {
                document.querySelectorAll(".menu-circuler button:nth-child(1)").forEach(el => el.click())
                openMenu = true;
            }
        }, 100)
    }, [])

    return (
        <div className="zuno-menu">
            <div className={"menu-circuler " + (open ? "active" : '')}>
                <div className="menu-circuler"></div>
                <div className="menu-click-circuler" onClick={() => {
                    setOpen(!open)
                    document.querySelectorAll(".menu-circuler button:nth-child(1)").forEach(el => el.click())
                }}></div>
                <FloatingButtons dimension={50} buttonsList={buttonsList} distance={70} top={'70px'} left={'calc(50% - 25px)'} degree={-180} direction="circular" />
            </div>
        </div>
    )
}

export default BottomMenu;